import * as React from 'react'

import { cn } from '@/src/lib/utils'

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>

const usualClassNames =
  'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50'

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => (
  <input type={type} className={cn(usualClassNames, className)} ref={ref} {...props} />
))
Input.displayName = 'Input'

const FileInput = React.forwardRef<HTMLInputElement, InputProps>(
  (
    props: InputProps & {
      className?: string
      onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
      value?: string | number | readonly string[] | undefined
    },
    ref
  ) => {
    // can't include value (can't set file input value) or className (the real control is invisible)
    const { value, className, ...spreadProps } = props
    const classNames = cn(usualClassNames, className)

    let readValue: string | undefined
    if (typeof value === 'string') {
      readValue = (props?.value as string)?.split('/')?.at(-1)
    }
    if (!readValue?.length) {
      readValue = undefined
    }
    if (!readValue) {
      readValue = 'No file chosen'
    }
    return (
      <label className={cn(classNames, 'relative')}>
        <input ref={ref} type="file" className="invisible absolute h-full w-full" {...spreadProps} />
        {/* TODO: if you wanted to add a browse button this is where you would do it */}
        <span>{readValue}</span>
      </label>
    )
  }
)
FileInput.displayName = 'FileInput'

export { Input, FileInput }
